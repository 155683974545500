import { Routes, Route } from 'react-router-dom';
import Header from '@components/header/Header';
import Sidebar from '@components/sidebar/Sidebar';
import routes, { RouteType } from '@utils/routes';
import { AppContainer } from '@styles/SApp';
import Login from '@components/modals/Login';
import { useMoralis } from 'react-moralis';
import { useEffect } from 'react';
import { useInsertAllUserFailedTransactionsMutation } from '@store/api/utilApi';
import useUser from '@hooks/useUser';
import AuthGuard from './guards/AuthGuard';

function App() {
  const { enableWeb3, isWeb3EnableLoading, isWeb3Enabled } = useMoralis();
  const { currentUser } = useUser();

  const [insertAllUserFailedTransactions] =
    useInsertAllUserFailedTransactionsMutation();

  const displayPage = ({ Component, isProjected }: RouteType) => {
    switch (true) {
      case isProjected:
        return (
          <AuthGuard>
            <Component />
          </AuthGuard>
        );
      default:
        return <Component />;
    }
  };

  useEffect(() => {
    if (currentUser) {
      // TODO: handle the loading state
      insertAllUserFailedTransactions(currentUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    const handleEnableWeb3 = async () => {
      await enableWeb3();
    };
    if (!isWeb3Enabled && !isWeb3EnableLoading) {
      handleEnableWeb3();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableWeb3]);

  return (
    <>
      <Header />
      <Login />
      <AppContainer className="AppContainer">
        <Sidebar />
        <Routes>
          {routes.map((route) => {
            const Page = displayPage(route);
            return <Route key={route.path} path={route.path} element={Page} />;
          })}
        </Routes>
      </AppContainer>
    </>
  );
}

export default App;
